.hero-title h1 {
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 700;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-left-container {
  width: 55%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20vmin;
  gap: 10px;
}
.hero-title {
  display: flex;
  flex-wrap: wrap;
}
.hero-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.hero-left-container svg {
  width: 10%;
  height: max-content;
}

.hero-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-image svg {
  width: 100%;
  height: auto;
}

.hero-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

@media only screen and (width < 900px) {
  .hero {
    flex-direction: column;
  }
  .hero-left-container {
    width: 100%;
    max-width: 600px;
    margin: 10vw auto;
  }
  .hero-left-container > svg {
    display: none;
  }
  .hero-text-container {
    gap: 10vw;
  }
  .hero-title h1 {
    font-size: clamp(3rem, 12vw, 6rem);
    line-height: clamp(3rem, 12vw, 6rem);
  }
}
