#footer {
  border-top: 1px solid rgba(238, 238, 238, 0.1);
  height: max-content;
  min-height: 300px;
  padding-top: 6.25rem;
  width: 100%;
  display: flex;
  position: relative;
  padding-bottom: 1rem;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.footer-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.footer-icons {
  display: inline-flex;
  vertical-align: middle;
  margin-right: 0.625rem;
  width: 24px;
  height: 24px;
}

.footer-icons img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.media {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.media-icon {
  width: 48px;
  height: 48px;
  display: flex;
  cursor: pointer;
}

.media-icon img {
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-bottom-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.125rem;
  opacity: 0.75;
}

.credits {
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  padding: 1rem;
  cursor: pointer;
}

.nav-options-copy ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.nav-options-copy ul li a {
  padding: 15px;
  transition: 0.2s ease-in-out;
}
.nav-options-copy ul li a:hover {
  color: var(--primary);
}

@media only screen and (width < 450px) {
  .footer-nav-options ul {
    flex-direction: column;
    gap: 20px;
  }
  .footer-nav-options ul li {
    width: 100%;
  }
  .footer-bottom-section {
    justify-content: center;
  }
}
