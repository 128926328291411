.button-image {
  width: 30px;
  height: 30px;
}
.button-image svg {
  width: 100%;
  height: 100%;
}

form input,
form textarea {
  background: var(--background2);
  border: none;
  border-radius: 1rem;
  font-size: 1.125rem;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--text);
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

form textarea {
  resize: none;
}

:focus-visible {
  outline: 1px solid var(--primary);
}

::placeholder {
  font-weight: 700;
}

form {
  display: flex;
  width: 100%;
  column-gap: 1.5rem;
  flex-wrap: wrap;
}
label {
  display: inline-block;
  margin-bottom: 0.625rem;
}

.primary-button {
  max-width: 240px;
}

.half-on-large {
  width: calc(50% - 0.75rem);
}

.full-on-large {
  width: 100%;
}

@media only screen and (width < 1200px) {
  .half-on-large {
    width: 100%;
  }
}

@media only screen and (width < 900px) {
  .primary-button {
    max-width: 100%;
  }
  form {
    flex-direction: column;
    column-gap: 0px;
    max-width: 600px;
  }
}
