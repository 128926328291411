@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text);
  text-decoration: none;
}

:root {
  --primary: #00adb5;
  --background: #222831;
  --background2: #393e46;
  --background2-opacity: 0.7;
  --background2-with-opacity: rgba(57, 62, 70, var(--background2-opacity));
  --text: #eeeeee;
  --nav-height: 130px;
  --scrollbar-width: 10px;
}

.App {
  font-family: "Poppins", sans-serif;
  background: var(--background);
  color: var(--text);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  height: 100vh;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f117;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eee;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

section {
  height: max-content;
  min-height: 100vh;
  padding-top: var(--nav-height);
  width: 100%;
  display: flex;
  scroll-snap-align: start;
  scroll-margin-bottom: 10px;
  position: relative;
}

.workspace {
  width: 80%;
  margin: auto;
  z-index: 2;
}

.primary-color {
  color: var(--primary);
}

.scroll-down-arrow {
  height: 80px;
  width: 80px;
  cursor: pointer;
  display: grid;
  place-items: center;
  background: rgba(57, 62, 70, 0.75);
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: 102;
  bottom: 4vh;
  left: 35%;
  transform: translate(-50%, 0);
}

.vertical-line {
  position: absolute;
  left: 35%;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 1px;
  background: rgba(238, 238, 238, 0.1);
}

/* Buttons */
.primary-button,
.secondary-button {
  color: var(--text);
  padding: 10px 32px;
  border-radius: 1000px;
  font-size: 1.125rem;
  font-weight: 700;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-family: inherit;
}

.primary-button:hover,
.secondary-button:hover {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
}

.primary-button {
  background: var(--primary);
  border: none;
}

.secondary-button {
  background: var(--background2-with-opacity);
  border: none;
  backdrop-filter: blur(2px);
}

@media only screen and (1200px < width <= 1400px) {
  .workspace {
    width: 85%;
  }
}

@media only screen and (width <= 1200px) {
  .workspace {
    width: 90%;
  }
}

@media only screen and (width < 900px) {
  :root {
    font-size: 14px;
  }
  .App {
    scroll-snap-type: none;
  }
  .scroll-down-arrow {
    width: 50px;
    height: 50px;
    bottom: calc(4vh + 15px);
  }
  .scroll-down-arrow svg {
    height: 50%;
    width: auto;
  }
}

@media only screen and (width <= 600px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}
